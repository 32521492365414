import { createRouter, createWebHistory } from 'vue-router'
import ListView from '../views/ListView.vue'
import DetailView from '../views/DetailView.vue'
import CompareView from '../views/CompareView.vue'
import config from '@/config'

const router = createRouter({
  history: createWebHistory(config.baseUrl),
  routes: [
    {
      path: '/vergelijk/:ids',
      name: 'compare',
      component: CompareView,
      props: true
    },
    {
      path: '/:model?',
      name: 'list',
      component: ListView
    },
    {
      path: '/:slug/:id',
      name: 'legacy-detail',
      redirect: (to) => {
        const matches = to.path.match(/[A-Za-z]*-([A-Za-z]*)-/)

        if (matches && matches.length === 2) {
          return {
            path: `/${matches[1]}${to.path}`
          }
        } else {
          return {
            path: `/`
          }
        }
      }
    },
    {
      path: '/:model/:slug/:id',
      name: 'detail',
      component: DetailView,
      props: true
    }
  ]
})

export default router
