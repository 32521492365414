<script setup lang="ts">
import HeroPart from '@/components/HeroPart.vue'
import CarItem from '@/components/CarItem.vue'
import FiltersPart from '@/components/FiltersPart.vue'
import FooterPart from '@/components/FooterPart.vue'
import LoadingBar from '@/components/LoadingBar.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import UspsList from '@/components/UspsList.vue'
import { useFiltersStore } from '@/stores/filters'
import { useIlsaStore } from '@/stores/ilsa'
import { ArrowUpIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { useHead, useSeoMeta } from '@unhead/vue'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import config, { AppMode } from '@/config'
import { useRoute } from 'vue-router'

const route = useRoute()
useHead({
  link: () => [
    {
      rel: 'canonical',
      href: `${import.meta.env.VITE_BASE_URL || `${window.location.protocol}//${window.location.host}`}${route.fullPath}`
    }
  ]
})

useSeoMeta({
  title: 'Overzicht',
  ogTitle: config.appName,
  description: () => {
    switch (config.appMode) {
      case AppMode.New:
        return 'Rijd binnen 30 dagen in een nieuwe CUPRA.'
      case AppMode.Used:
        return 'Met een CUPRA Approved occasion ben je verzekerd van topkwaliteit en uitgebreide garantie voor zorgeloos rijden. Bekijk het uitgebreide aanbod van alle officiële CUPRA partners in Nederland.'
      default:
        return null
    }
  },
  ogDescription: () => {
    switch (config.appMode) {
      case AppMode.New:
        return 'Rijd binnen 30 dagen in een nieuwe CUPRA.'
      case AppMode.Used:
        return 'Met een CUPRA Approved occasion ben je verzekerd van topkwaliteit en uitgebreide garantie voor zorgeloos rijden. Bekijk het uitgebreide aanbod van alle officiële CUPRA partners in Nederland.'
      default:
        return null
    }
  },
  ogImage: `${document.location.origin}/img/leon@2x.webp`
})

const searchResultsList = ref<HTMLDivElement>()

const ilsaStore = useIlsaStore()
const filtersStore = useFiltersStore()

ilsaStore.retrieveSearchResults()

onMounted(() => window.addEventListener('scroll', handleScroll))
onUnmounted(() => window.removeEventListener('scroll', handleScroll))

const handleScroll = () => {
  let element = searchResultsList.value
  if (!element) {
    return
  }

  if (element.getBoundingClientRect().bottom - 200 < window.innerHeight) {
    ilsaStore.retrieveMoreSearchResults()
  }
}

const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
</script>

<template>
  <div
    class="z-50 fixed bottom-10 right-10 p-3 cursor-pointer bg-white border-2 border-black shadow-lg"
    @click="scrollToTop"
  >
    <ArrowUpIcon class="h-6 w-6" />
  </div>

  <div
    class="hidden lg:block z-50 fixed bottom-10 right-28 p-3 bg-black text-white border-2 border-black shadow-lg"
    v-if="ilsaStore.compareVehiclsIds.size > 1"
  >
    <RouterLink
      :to="`/vergelijk/${[...ilsaStore.compareVehiclsIds].join(',')}`"
      class="cursor-pointer"
    >
      Vergelijk {{ ilsaStore.compareVehiclsIds.size }} auto's
    </RouterLink>
    <XMarkIcon
      class="inline-block h-6 w-6 mb-1 cursor-pointer"
      @click="ilsaStore.compareVehiclsIds.clear()"
    />
  </div>

  <HeroPart />

  <UspsList />
  <LoadingBar :is-loading="ilsaStore.isLoading" />

  <main v-if="ilsaStore.searchResults" class="container mx-auto px-4 my-8" id="results">
    <!-- <div class="mb-8 xl:mx-32">
      <ModelPicker :car-models="carModels" v-model="carModelSlug" />
    </div> -->

    <div>
      <FiltersPart />

      <div class="my-8 md:flex items-end justify-between">
        <div>
          <template v-if="ilsaStore.searchResults.num_results === 0">
            Er zijn helaas geen CUPRA's beschikbaar<template v-if="filtersStore.isFiltering">
              die aan je filters voldoen</template
            >.
          </template>
          <template v-else-if="ilsaStore.searchResults.num_results === 1">
            1 CUPRA beschikbaar<template v-if="filtersStore.isFiltering">
              die aan je filters voldoet</template
            >.
          </template>
          <template v-else>
            {{ ilsaStore.searchResults.num_results }} CUPRA's beschikbaar<template
              v-if="filtersStore.isFiltering"
            >
              die aan je filters voldoen</template
            >.</template
          >
        </div>
        <div class="flex flex-col gap-1">
          <select
            id="order"
            v-model="filtersStore.order"
            aria-label="Sortering"
            class="text-sm p-2 pr-8 bg-white border-cupgragray focus:ring-copper focus:border-copper"
          >
            <option :value="undefined">Geen voorkeur</option>
            <option value="price">Prijs &darr;</option>
            <option value="price DESC">Prijs &uarr;</option>
            <option value="mileage">Kilometerstand &darr;</option>
            <option value="mileage DESC">Kilometerstand &uarr;</option>
            <option value="year">Bouwjaar &darr;</option>
            <option value="year DESC">Bouwjaar &uarr;</option>
          </select>
        </div>
      </div>

      <div
        v-if="ilsaStore.searchResults.num_results > 0"
        class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-8 mt-8 mb-16"
        ref="searchResultsList"
      >
        <CarItem v-for="(car, ix) in ilsaStore.searchResults.results" :car="car" :key="ix" />
        <div
          v-if="ilsaStore.isRetrievingMoreResults"
          class="relative overflow-hidden p-6 h-full flex flex-col justify-center"
        >
          <LoadingSpinner />
        </div>
      </div>
    </div>
  </main>

  <FooterPart />
</template>
