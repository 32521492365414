<script setup lang="ts">
import { useIlsaStore } from '@/stores/ilsa'
import { useHead } from '@unhead/vue'
import { toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps<{
  ids: String
}>()

const ilsaStore = useIlsaStore()
const route = useRoute()

useHead({
  link: () => [
    {
      rel: 'canonical',
      href: `${import.meta.env.VITE_BASE_URL || `${window.location.protocol}//${window.location.host}`}${route.fullPath}`
    }
  ]
})

const ids = new Set<String>(props.ids.split(','))

const classForClassification = (classification: string) => {
  switch (classification) {
    case 'best':
      return 'bg-green-400'
    case 'worst':
      return 'bg-red-400'
  }
}
const { compareOnlyDifferences } = toRefs(ilsaStore)

watch(
  () => ilsaStore.compareOnlyDifferences,
  () => {
    ilsaStore.retrieveComparison()
  }
)

ilsaStore.compareVehiclsIds = ids
ilsaStore.retrieveComparison()
</script>

<template>
  <main v-if="ilsaStore.comparison" class="container mx-auto px-4 my-8">
    <RouterLink to="/" class="text-sm cursor-pointer hover:underline block mb-4"
      >&lt; Terug naar overzicht</RouterLink
    >
    <h1 class="text-3xl mb-4">Vergelijk auto's</h1>
    <label
      ><input type="checkbox" v-model="compareOnlyDifferences" class="text-copper" />
      Toon alleen verschillen
    </label>
    <table cellspacing="4" cellpadding="4" class="w-full text-sm text-left">
      <thead>
        <tr>
          <th></th>
          <th v-for="(vehicle, ix) in ilsaStore.comparison.vehicles" :key="ix" class="pb-4">
            <h2 class="text-2xl font-light">
              {{ vehicle.general.make.name }} {{ vehicle.general.model.name }}
            </h2>
            <div class="font-bold md:line-clamp-1 mb-4">
              {{ vehicle.general.type.name }}
            </div>
            <RouterLink
              :to="`/${vehicle.identification.slug}/${vehicle.id}`"
              class="block cursor-pointer mb-4"
            >
              <img :srcset="vehicle.images[0].srcset" class="w-64 h-32 object-cover" />
            </RouterLink>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(section, ix) in ilsaStore.comparison.sections" :key="ix">
          <tr class="bg-gray-300">
            <td
              :colspan="ilsaStore.comparison.vehicles.length + 1"
              class="text-base uppercase text-petrol font-bold py-2 pl-4"
            >
              {{ section.title }}
            </td>
          </tr>

          <template v-for="(subsection, ix) in section.subsections" :key="ix">
            <!-- {{ subsection.title }} -->
            <tr v-for="(field, ix) in subsection.fields" :key="ix" class="border-b">
              <td class="uppercase text-gray-500 text-xs">{{ field.label }}</td>
              <td
                v-for="(vehicleField, ix) in field.vehicles"
                :key="ix"
                :class="classForClassification(vehicleField.classification)"
              >
                {{ vehicleField.display_value }}
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </main>
</template>
