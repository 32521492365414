<script setup lang="ts">
import config, { AppMode } from '@/config'
import CheckmarkIcon from './CheckmarkIcon.vue'

defineProps({
  direction: {
    type: String,
    default: 'horizontal'
  },
  showDetails: {
    type: Boolean,
    default: true
  }
})

const appMode = config.appMode
</script>

<template>
  <div class="bg-petrol py-6">
    <div v-if="appMode === AppMode.Used" class="container mx-auto px-4 text-oxygen">
      <div
        class="flex flex-col gap-8"
        :class="direction === 'horizontal' ? 'lg:grid grid-cols-3' : ''"
      >
        <div class="flex gap-4 items-center lg:items-start">
          <CheckmarkIcon class="mt-1 shrink-0" />
          <div>
            <h2 class="font-bold">Kwaliteit gegarandeerd</h2>
            <span v-if="showDetails" class="text-sm hidden lg:inline"
              >Met een CUPRA Approved occasion ben je verzekerd van topkwaliteit en uitgebreide
              garantie voor zorgeloos rijden.
            </span>
          </div>
        </div>
        <div class="flex gap-4 items-center lg:items-start">
          <CheckmarkIcon class="mt-1 shrink-0" />
          <div>
            <h2 class="font-bold">Uitgebreide technische keuring</h2>
            <span v-if="showDetails" class="text-sm hidden lg:inline"
              >Onze uitgebreide keuring zorgt ervoor dat elke CUPRA grondig wordt gecontroleerd op
              veiligheid en prestaties.
            </span>
          </div>
        </div>
        <div class="flex gap-4 items-center lg:items-start">
          <CheckmarkIcon class="mt-1 shrink-0" />
          <div>
            <h2 class="hidden lg:block font-bold">Zelf ervaren</h2>
            <h2 class="lg:hidden font-bold">Proefrit altijd mogelijk</h2>
            <span v-if="showDetails" class="text-sm hidden lg:inline"
              >Zin in een uitgebreide proefrit? Dat kan uiteraard! Neem contact op en ervaar de
              CUPRA zelf.
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="appMode === AppMode.New" class="container mx-auto px-4 text-oxygen">
      <div
        class="flex flex-col gap-8"
        :class="direction === 'horizontal' ? 'lg:grid grid-cols-3' : ''"
      >
        <div class="flex gap-4 items-center lg:items-start">
          <CheckmarkIcon class="mt-1 shrink-0" />
          <div>
            <h3 class="font-bold">Direct leverbaar</h3>
            <span v-if="showDetails" class="text-sm hidden lg:inline">
              Met een CUPRA uit voorraad rijd je altijd binnen 30 dagen in jouw nieuwe auto.
            </span>
          </div>
        </div>
        <div class="flex gap-4 items-center lg:items-start">
          <CheckmarkIcon class="mt-1 shrink-0" />
          <div>
            <h3 class="font-bold">Ruim aanbod</h3>
            <span v-if="showDetails" class="text-sm hidden lg:inline">
              Dankzij onze ruime voorraad vind je altijd het model en de uitvoering die je zoekt.
            </span>
          </div>
        </div>
        <div class="flex gap-4 items-center lg:items-start">
          <CheckmarkIcon class="mt-1 shrink-0" />
          <div>
            <h3 class="hidden lg:block font-bold">Zelf ervaren</h3>
            <h3 class="lg:hidden font-bold">Proefrit altijd mogelijk</h3>
            <span v-if="showDetails" class="text-sm hidden lg:inline">
              Een proefrit maken? Dat kan uiteraard. Neem daarvoor contact op met de CUPRA
              Specialist.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
