import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { Vehicle } from '@/models/Vehicle'
import type { SearchResultsResponse } from '@/models/SearchResults'
import { useFiltersStore } from './filters'
import IlsaFilters from '@/services/IlsaService/IlsaFilters'
import IlsaService from '@/services/IlsaService'
import type { DropDownContentsResponse } from '@/models/DropDownContents'
import { AxiosError } from 'axios'
import config from '@/config'

const baseUrl = config.ilsaBaseUrl
const instance = config.ilsaInstance
const locale = 'nl_NL'
const ilsaService = new IlsaService(baseUrl, instance, locale)

export const useIlsaStore = defineStore('ilsa', () => {
  const filtersStore = useFiltersStore()

  const isLoading = ref(false)
  const isRetrievingMoreResults = ref(false)
  const loadingError = ref<number | undefined>(undefined)
  const searchResults = ref<SearchResultsResponse | null>(null)
  const vehicle = ref<Vehicle | null>(null)

  const dropDownContents = ref<DropDownContentsResponse | null>(null)

  const comparison = ref<any>(null)

  const compareVehiclsIds = ref<Set<String>>(new Set<String>())
  const compareOnlyDifferences = ref<boolean>(true)

  function toggleVehicleIdForComparison(id: String) {
    if (compareVehiclsIds.value.has(id)) {
      compareVehiclsIds.value.delete(id)
    } else {
      compareVehiclsIds.value.add(id)
    }
  }

  async function retrieveSearchResults() {
    isLoading.value = true

    const filters = filtersStore.filters

    let location
    if (filtersStore.postalCode?.length === 4) {
      location = `nl ${filtersStore.postalCode}`
    }

    try {
      const results = await Promise.all([
        ilsaService.searchresults(filters, 0, location),
        ilsaService.dropdowncontents(filters, IlsaFilters.DROPDOWN_FILTERS, location)
      ])
      searchResults.value = results[0]
      dropDownContents.value = results[1]
    } catch (e) {
      if (e instanceof AxiosError) {
        loadingError.value = e.response?.status
      } else {
        loadingError.value = 999
      }
      searchResults.value = null
      dropDownContents.value = null
    }

    isLoading.value = false
  }

  async function retrieveMoreSearchResults() {
    if (!searchResults.value || isRetrievingMoreResults.value) {
      // Don't have search results to expand, or already loading more
      return false
    }

    if (searchResults.value.results.length >= searchResults.value.num_results) {
      // All data is loaded
      return false
    }

    isRetrievingMoreResults.value = true

    try {
      const data = await ilsaService.searchresults(
        filtersStore.filters,
        searchResults.value.results.length
      )
      const newSearchResults = searchResults.value
      if (!newSearchResults) {
        // Something happened to the old search results in the meantime, cancel
        return false
      }

      newSearchResults.results.push(...data.results)

      searchResults.value = newSearchResults
    } catch (e) {
      if (e instanceof AxiosError) {
        loadingError.value = e.response?.status
      } else {
        loadingError.value = 999
      }
    }

    isRetrievingMoreResults.value = false
  }

  async function retrieveVehicle(id: string) {
    vehicle.value = null

    isLoading.value = true

    try {
      const data = await ilsaService.vehicle(id)
      vehicle.value = data.vehicle
    } catch (e) {
      if (e instanceof AxiosError) {
        loadingError.value = e.response?.status
      } else {
        loadingError.value = 999
      }
      vehicle.value = null
    }

    isLoading.value = false
  }

  async function retrieveComparison() {
    comparison.value = await ilsaService.compare(
      compareVehiclsIds.value,
      compareOnlyDifferences.value
    )
  }

  return {
    isLoading,
    isRetrievingMoreResults,
    loadingError,
    searchResults,
    vehicle,
    dropDownContents,
    compareVehiclsIds,
    compareOnlyDifferences,
    comparison,
    toggleVehicleIdForComparison,
    retrieveSearchResults,
    retrieveMoreSearchResults,
    retrieveVehicle,
    retrieveComparison
  }
})
