<script setup lang="ts">
import config, { AppMode } from '@/config'
import CupraLogo from './CupraLogo.vue'
import CupraApprovedWordmark from './CupraApprovedWordmark.vue'
import CupraWordmark from './CupraWordmark.vue'

const appMode = config.appMode
</script>

<template>
  <div class="bg-cupragray">
    <nav class="container mx-auto px-4">
      <div class="flex items-center gap-8">
        <a href="/" aria-label="CUPRA Approved occasions - Home">
          <div class="flex items-center gap-2 h-16 p-4 fill-white">
            <CupraLogo class="w-8" />
            <CupraApprovedWordmark v-if="appMode === AppMode.Used" class="h-10 hidden sm:block" />
            <CupraWordmark v-else-if="appMode === AppMode.New" class="ml-2 h-8 hidden sm:block" />
          </div>
        </a>

        <RouterLink :to="{ name: 'list', params: {} }" class="text-white">
          <template v-if="appMode === AppMode.Used">Occasions</template>
          <template v-else-if="appMode === AppMode.New">Voorraad</template>
          <template v-else>Home</template>
        </RouterLink>

        <a href="https://www.cupraofficial.nl/" target="_blank" class="text-white" rel="noopener">
          CUPRAofficial.nl
        </a>
      </div>
    </nav>
  </div>
</template>
