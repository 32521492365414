<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="250 0 550 226.3"
  >
    <g>
      <path
        d="M450.1,84c0,1.2-1.1,2.2-2.4,2.2h-57.9c-1.3,0-2.4-1-2.4-2.2V50h-16.4v37.2c0,7,6.2,12.7,13.8,12.7h68.1
			c7.6,0,13.8-5.7,13.8-12.7V50h-16.4V84z"
      />
      <path
        d="M791.7,50h-81.8c-7.6,0-13.8,5.7-13.8,12.7l0.1,37.2h15.5V84.7h64.7v15.2h15.5V50
			C791.9,50,791.8,50.1,791.7,50z M776.5,72.6c-22.3-0.1-64.8-0.2-64.8,0v-9.4c0-1.2,1.1-2.2,2.4-2.2h62.4V72.6z"
      />
      <path
        d="M683.3,72V61.7c0-7-6.2-11.7-13.8-11.7h-81.8l0,49.9l15.9,0V84.7h53.6l9.4,15.2h17.8l-10.9-15.9
			C678.5,84.1,683.3,79.2,683.3,72z M669.2,70.4c0,1.2-1.1,2.2-2.4,2.2h-63.1V60.9h63.1c1.3,0,2.4,1,2.4,2.2V70.4z"
      />
      <path
        d="M494.7,84.7h66.5C571.9,84.7,575,79,575,72V61.7c0-7-3.7-11.7-13.8-11.7h-82v50h15.5V84.7z M494.7,60.9h63.8
			c1.3,0,2.4,1,2.4,2.2v7.2c0,1.2-1.1,2.2-2.4,2.2h-63.8V60.9z"
      />
      <path
        d="M276.3,99.9H358V86.3h-79c-1.3,0-2.4-1-2.4-2.2V65.9c0-1.2,1.1-2.2,2.4-2.2h79V50h-81.8
			c-7.6,0-13.8,5.7-13.8,12.7v24.5C262.5,94.2,268.7,99.9,276.3,99.9z"
      />
      <path
        d="M285.2,123.5l-22.6,51.4h6.5l5.1-12h22.5l5.1,12h6.5l-22.6-51.4H285.2z M276.5,157.6l8.9-20.9l8.9,20.9H276.5
			z"
      />
      <path
        d="M351.7,124.2c-3.3,0-10,0.3-13.4,0.7v50h6.1v-16.8c2.4,0.4,4.7,0.6,7.3,0.6c13.9,0,20.5-7,20.5-18.1
			S365.5,124.2,351.7,124.2z M351,153.2c-2.3,0-4.4-0.2-6.7-0.6v-22.7c2.2-0.1,4.6-0.2,6.5-0.2c10.8,0,15,3.1,15,10.9
			C365.9,149,361.1,153.2,351,153.2z"
      />
      <path
        d="M418.1,124.2c-3.3,0-10,0.3-13.4,0.7v50h6.1v-16.8c2.4,0.4,4.7,0.6,7.3,0.6c13.9,0,20.5-7,20.5-18.1
			S432,124.2,418.1,124.2z M417.5,153.2c-2.3,0-4.4-0.2-6.7-0.6v-22.7c2.2-0.1,4.6-0.2,6.5-0.2c10.8,0,15,3.1,15,10.9
			C432.4,149,427.6,153.2,417.5,153.2z"
      />
      <path
        d="M505.1,140.1c0-10.6-6.7-15.8-20.6-15.8c-4,0-10.6,0.4-13.2,0.7v50h6.1V157c2.5,0.4,5.3,0.6,7.7,0.6h1.5
			l11.4,17.3h7l-12.1-18.3C500.7,154.4,505.1,148.7,505.1,140.1z M484.7,152.1c-2.3,0-4.9-0.2-7.3-0.6V130c2.7-0.2,4.4-0.2,4.8-0.2
			c12.6,0,16.8,2.5,16.8,10.1C498.9,148.1,494.2,152.1,484.7,152.1z"
      />
      <path
        d="M560.9,124.2c-14.9,0-25.2,11.5-25.2,25.7c0,14.1,10.3,25.7,25.2,25.7c14.9,0,25.2-11.5,25.2-25.7
			C586.1,135.8,575.8,124.2,560.9,124.2z M560.9,170.1c-11.6,0-19-9.3-19-20.2s7.4-20.2,19-20.2c11.6,0,19,9.3,19,20.2
			S572.5,170.1,560.9,170.1z"
      />
      <polygon
        points="633.7,163.6 617.5,124.9 611,124.9 633.5,176.3 633.9,176.3 656.4,124.9 649.9,124.9 		"
      />
      <polygon
        points="692.3,151 714.5,151 714.5,145.6 692.3,145.6 692.3,130.3 716.2,130.3 716.2,124.9 686.3,124.9 
			686.3,174.9 716.6,174.9 716.6,169.6 692.3,169.6 		"
      />
      <path
        d="M767.9,124.9h-18.2v50h18.2c14.6,0,24-11.9,24-25C791.9,136.8,782.5,124.9,767.9,124.9z M767,169.6h-11.3
			v-39.3H767c11.6,0,18.7,9.4,18.7,19.6S778.7,169.6,767,169.6z"
      />
    </g>
  </svg>
</template>
