<script setup lang="ts">
import type { Vehicle } from '@/models/Vehicle'
import { Splide, SplideSlide, Options } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
import { onMounted, ref } from 'vue'

const props = defineProps<{ vehicle: Vehicle }>()

const main = ref<InstanceType<typeof Splide>>()
const thumbs = ref<InstanceType<typeof Splide>>()
const slides = props.vehicle.images

const mainOptions: Options = {
  type: 'loop',
  perPage: 1,
  perMove: 1,
  gap: '1rem',
  lazyLoad: 'sequential',
  pagination: false,
  heightRatio: 0.75,
  cover: false
}

const thumbsOptions: Options = {
  type: 'slide',
  rewind: true,
  gap: '1rem',
  pagination: false,
  fixedWidth: 110,
  fixedHeight: 70,
  cover: true,
  focus: 'center',
  isNavigation: true,
  updateOnMove: true
}

onMounted(() => {
  const thumbsSplide = thumbs.value?.splide

  if (thumbsSplide) {
    main.value?.sync(thumbsSplide)
  }
})
</script>

<style>
.splide__track--nav > .splide__list > .splide__slide {
  border-width: 2px;
}
.splide__track--nav > .splide__list > .splide__slide.is-active {
  border-width: 2px;
}
.splide__slide img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
</style>

<template>
  <div>
    <Splide :options="mainOptions" ref="main">
      <SplideSlide v-for="(slide, ix) in slides" :key="ix" class="bg-cupragray flex items-center">
        <img :data-splide-lazy-srcset="slide.srcset" :alt="`Foto ${ix + 1}`" />
      </SplideSlide>
    </Splide>

    <Splide :options="thumbsOptions" ref="thumbs" class="mt-4 mb-2">
      <SplideSlide v-for="(slide, ix) in slides" :key="ix">
        <img :src="slide.src" :alt="`Foto ${ix + 1}`" />
      </SplideSlide>
    </Splide>
  </div>
</template>
