import type { VehicleResponse } from '@/models/Vehicle'
import type { SearchResultsResponse } from '@/models/SearchResults'
import axios from 'axios'
import { DropDownContentsResponse } from '@/models/DropDownContents'
import IlsaFilters from './IlsaFilters'

export default class IlsaService {
  private baseUrl: string
  private instance: string
  private locale: string

  public static readonly RESULTS_PER_PAGE = 25

  constructor(baseUrl: string, instance: string, locale: string) {
    this.baseUrl = baseUrl
    this.instance = instance
    this.locale = locale
  }

  async dropdowncontents(
    filters: IlsaFilters,
    fields: Array<string>,
    location?: string
  ): Promise<DropDownContentsResponse> {
    const url = `${this.baseUrl}/${this.instance}/dropdowncontents`

    const response = await axios.get(url, {
      params: {
        _locale: this.locale,
        _location: location,
        _fields: fields,
        ...filters.params(false)
      },
      paramsSerializer: { indexes: null }
    })

    return new DropDownContentsResponse(response.data)
  }

  async searchresults(
    filters: IlsaFilters,
    offset?: number,
    location?: string
  ): Promise<SearchResultsResponse> {
    const url = `${this.baseUrl}/${this.instance}/searchresults`

    const response = await axios.get<SearchResultsResponse>(url, {
      params: {
        _locale: this.locale,
        _location: location,
        _fieldset: 'searchresults',
        _offset: offset || 0,
        _limit: IlsaService.RESULTS_PER_PAGE,
        ...filters.params()
      }
    })

    return response.data
  }

  async vehicle(id: string): Promise<VehicleResponse> {
    const url = `${this.baseUrl}/${this.instance}/vehicle`

    const response = await axios.get<VehicleResponse>(url, {
      params: {
        _locale: this.locale,
        _fieldset: 'details',
        id
      }
    })

    return response.data
  }

  async compare(ids: Set<String>, onlyDifferences: boolean) {
    const url = `${this.baseUrl}/${this.instance}/compare`

    const response = await axios.get<VehicleResponse>(url, {
      params: {
        _locale: this.locale,
        _fieldset: 'compare',
        _only_differences: onlyDifferences,
        id: [...ids]
      },
      paramsSerializer: { indexes: null }
    })

    return response.data
  }
}
