<script setup lang="ts">
import type { Vehicle } from '@/models/Vehicle'
import AppButton from './AppButton.vue'
import TextField from './TextField.vue'
import axios from 'axios'
import { ref } from 'vue'
import LoadingSpinner from './LoadingSpinner.vue'
import config, { AppMode } from '@/config'

interface FormErrors {
  firstName?: string
  lastname?: string
  email?: string
  phone?: string
  remarks?: string
  tradeInLicensePlate?: string
  tradeInOdometer?: string
}

const props = defineProps<{
  vehicleId: string
  vehicle: Vehicle
}>()

const firstName = ref<string>('')
const lastName = ref<string>('')
const email = ref<string>('')
const phone = ref<string>('')
const remarks = ref<string>('')
const testDrive = ref<boolean>(false)
const tradeIn = ref<boolean>(false)
const tradeInLicensePlate = ref<string>('')
const tradeInOdometer = ref<string>('')

const isSubmitting = ref<boolean>(false)
const isSubmitted = ref<boolean>(false)
const formErrors = ref<FormErrors>({})
const apiError = ref<string | undefined>(undefined)

defineEmits(['close'])

const validateForm = (): boolean => {
  const newFormErrors: FormErrors = {}
  let hasErrors = false

  if (firstName.value.length < 2) {
    newFormErrors.firstName = 'Vul je voornaam in'
    hasErrors = true
  }
  if (lastName.value.length < 2) {
    newFormErrors.lastname = 'Vul je achternaam in'
    hasErrors = true
  }
  // if (phone.value.length !== 10) {
  //   newFormErrors.phone = 'Vul een geldig telefoonnummer in'
  //   hasErrors = true
  // }
  if (email.value.length < 6 || !email.value.includes('@') || !email.value.includes('.')) {
    newFormErrors.email = 'Vul een geldig e-mailadres in'
    hasErrors = true
  }
  if (email.value.indexOf('+') > -1) {
    newFormErrors.email = 'Vul een e-mailadres zonder plus-teken in'
    hasErrors = true
  }

  if (hasErrors) {
    window.utag &&
      window.utag.link({
        tealium_event: 'form_error',
        url_of_page: window.location.href,
        brand: props.vehicle.general.make.name,
        eventCategory: 'Lead form',
        car_model: props.vehicle.general.model.name,
        car_model_style: props.vehicle.general.type.name,
        form_name: 'Lead form'
      })
  }

  formErrors.value = newFormErrors

  return !hasErrors
}

const submitLead = async () => {
  if (!validateForm()) {
    return
  }

  isSubmitted.value = false
  isSubmitting.value = true

  try {
    await axios.post(
      '/.netlify/functions/submit-lead',
      {
        url: window.location.href,
        vin: props.vehicle.identification.vin,
        dealerId: props.vehicle.advertiser.id,
        vehicleIsUsed: props.vehicle.condition.used,
        vehicleLicensePlate:
          props.vehicle.identification.license_plate?.raw ||
          props.vehicle.identification.license_plate?.formatted,
        vehicleReference: props.vehicle.identification.referencenumber,
        vehicleBrand: props.vehicle.general.make.name,
        vehicleModel: props.vehicle.general.model.name,
        vehicleDescription: `${props.vehicleId} - ${props.vehicle.general.make.name} ${props.vehicle.general.model.name} ${props.vehicle.general.type.name} (${props.vehicle.body.colour.formatted})`,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phone: phone.value,
        remarks: remarks.value,
        testDrive: testDrive.value,
        tradeIn: tradeIn.value,
        tradeInLicensePlate: tradeInLicensePlate.value,
        tradeInOdometer: tradeInOdometer.value
      },
      {
        headers: {
          'X-API-Key': config.apiKey
        }
      }
    )
  } catch (e) {
    apiError.value = `${e}`

    window.utag &&
      window.utag.link({
        tealium_event: 'form_error',
        url_of_page: window.location.href,
        brand: props.vehicle.general.make.name,
        eventCategory: 'Lead form',
        car_model: props.vehicle.general.model.name,
        car_model_style: props.vehicle.general.type.name,
        form_name: 'Lead form',
        form_field_error: 'apiError',
        form_error_message: `${e}`
      })

    return
  }

  window.utag &&
    window.utag.link({
      tealium_event: 'form_submit',
      url_of_page: window.location.href,
      brand: props.vehicle.general.make.name,
      eventCategory: 'Lead form',
      car_model: props.vehicle.general.model.name,
      car_model_style: props.vehicle.general.type.name,
      form_name: 'Lead form',
      dealerId: props.vehicle.advertiser.id
    })
  isSubmitted.value = true
  isSubmitting.value = false
}
</script>

<template>
  <h1 class="text-3xl mb-3">Neem contact op</h1>

  <LoadingSpinner v-if="isSubmitting" />

  <div v-else-if="isSubmitted">
    <p v-if="config.appMode === AppMode.New">
      Bedankt voor je aanvraag. De CUPRA Specialist neemt zo spoedig mogelijk contact met je op.
    </p>
    <p v-else>Bedankt voor je aanvraag. De verkoper neemt zo spoedig mogelijk contact met je op.</p>

    <AppButton @click="$emit('close')">Sluiten</AppButton>
  </div>

  <div v-else>
    <p>
      Neem telefonisch contact op met {{ vehicle.advertiser.name }} via
      <a :href="`tel:${vehicle.advertiser.telephone}`" class="font-medium underline">
        {{ vehicle.advertiser.telephone }}</a
      >
      of vul het formulier in zodat er contact met jou opgenomen kan worden.
    </p>
    <div class="mt-8">
      <div class="text-sm">Interesse in:</div>
      <div class="font-medium">
        Referentie: {{ vehicle.identification.referencenumber }}<br />
        {{ vehicle.general.make.name }} {{ vehicle.general.model.name }}
        {{ vehicle.general.type.name }} ({{ vehicle.body.colour.formatted }})
      </div>
    </div>

    <form @submit.prevent="submitLead">
      <div class="grid md:grid-cols-2 gap-2 mt-8">
        <TextField
          label="Voornaam"
          type="text"
          autocomplete="given-name"
          v-model="firstName"
          :error="formErrors.firstName"
        />
        <TextField
          label="Achternaam"
          type="text"
          autocomplete="family-name"
          v-model="lastName"
          :error="formErrors.lastname"
        />
        <TextField
          label="E-mailadres"
          type="email"
          autocomplete="email"
          v-model="email"
          :error="formErrors.email"
        />
        <TextField
          label="Telefoonnummer (optioneel)"
          type="tel"
          autocomplete="tel"
          v-model="phone"
          :error="formErrors.phone"
        />

        <TextField
          class="md:col-span-2"
          label="Opmerkingen (optioneel)"
          type="text"
          v-model="remarks"
          :error="formErrors.remarks"
        />
        <label class="mt-4 md:col-span-2"
          ><input
            type="checkbox"
            class="text-copper mr-2 mb-1 focus:outline-copper"
            :value="true"
            v-model="testDrive"
          />
          Ik heb interesse in een proefrit
        </label>

        <label class="mt-4 md:col-span-2"
          ><input
            type="checkbox"
            class="text-copper mr-2 mb-1 focus:outline-copper"
            :value="true"
            v-model="tradeIn"
          />
          Ik heb interesse in inruilen
        </label>

        <template v-if="tradeIn">
          <TextField
            label="Kenteken (optioneel)"
            type="text"
            v-model="tradeInLicensePlate"
            :error="formErrors.tradeInLicensePlate"
          />
          <TextField
            label="Kilometerstand ± (optioneel)"
            type="number"
            v-model="tradeInOdometer"
            :error="formErrors.tradeInOdometer"
          />
        </template>
      </div>

      <AppButton variant="primary" class="mt-8" type="submit">
        Neem contact met mij op &gt;
      </AppButton>
    </form>
  </div>
</template>
