<template>
  <div class="bg-cupgragray">
    <footer class="container mx-auto px-4 my-8 text-muted text-xs">
      Pon’s Automobielhandel B.V.* (Pon) stelt het op prijs dat u geïnteresseerd bent in onze
      producten en een bezoek brengt aan één van onze sites. Hoewel de informatie op deze site
      permanent zo accuraat en actueel mogelijk wordt weergegeven, zijn wijzigingen in modellen,
      uitvoeringen, prijzen, technische specificaties, afbeeldingen, of andere informatie op deze
      site te allen tijde voorbehouden. Het is onmogelijk om transmissiefouten volledig uit te
      sluiten. Aan de inhoud van deze site kunnen derhalve op geen enkele wijze rechten worden
      ontleend.
    </footer>
  </div>
</template>
