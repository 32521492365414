<script setup lang="ts">
import config from '@/config'
import type { SearchResultCar } from '@/models/SearchResults'
import { useIlsaStore } from '@/stores/ilsa'

defineProps<{
  car: SearchResultCar
}>()

const ilsaStore = useIlsaStore()

const enableComparison = config.isComparisonEnabled
</script>

<template>
  <RouterLink
    :to="`/${car.general.model.name.toLowerCase()}/${car.identification.slug}/${car.id}`"
    class="bg-white text-cupragray hover:shadow-lg scale-100 motion-safe:hover:scale-[1.01] transition-all duration-250 focus:outline focus:outline-2 focus:outline-copper"
  >
    <div class="relative overflow-hidden p-6 h-full flex flex-col justify-between">
      <div>
        <h2 class="text-2xl font-light text-center">
          {{ car.general.make.name }} {{ car.general.model.name }}
        </h2>
        <div class="font-bold text-center md:line-clamp-1">
          {{ car.general.type.name }}
        </div>
        <div class="text-center md:line-clamp-1">
          {{ car.powertrain.engine.energy.type.category.display_value }} |
          {{ car.condition.odometer.formatted }} | {{ car.general.year }}
        </div>
      </div>

      <img
        :srcset="car.images[0].srcset"
        :alt="`${car.general.make.name} ${car.general.model.name} ${car.general.type.name}`"
        class="my-6 aspect-[1.33333] object-contain bg-black"
        width="432"
        height="324"
        loading="lazy"
      />

      <div class="flex flex-col gap-3 items-center">
        <div
          v-if="car.sales_conditions.pricing.asking.before_discount !== null"
          class="flex gap-4 justify-center"
        >
          <h3 class="line-through opacity-60">
            {{ car.sales_conditions.pricing.asking.before_discount!.formatted }}
          </h3>
          <h3 class="font-bold">
            <span class="font-normal">Nu voor</span>
            {{ car.sales_conditions.pricing.asking.formatted }}
          </h3>
        </div>
        <div v-else class="flex gap-4 justify-center">
          <h3 class="font-bold">
            <span class="font-normal">Nu voor</span>
            {{ car.sales_conditions.pricing.asking.formatted }}
          </h3>
        </div>

        <button class="mt-2 border border-cupragray hover:bg-oxygen hover:text-black h-12 w-full">
          Bekijk deze auto &gt;
        </button>
        <label v-if="enableComparison" @click.stop class="hidden lg:block">
          <input
            type="checkbox"
            :value="car.id"
            v-model="ilsaStore.compareVehiclsIds"
            class="text-copper focus:ring-0"
          />
          Vergelijk
        </label>
      </div>
    </div>
  </RouterLink>
</template>
